.face {
    width: fit-content;
    height: fit-content;
    border-radius: 24px;
    padding-bottom: 0px;
    position: relative;
}

.face::after {
    content: '';
    background-image: url('/src/assets/cloud-animated.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 160px;
    height: 120px;
    top: -19%;
    left: -8%;
}

.tool {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    padding-top: 2rem;
}

.marks {
    max-width: 350px;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
}


@media (max-width:960px) {
    .tool {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        width: 100%;
    }
}

@media (min-width:961px) and (max-width: 1024px) {
    .marks {
        /*Cuando hay 16 marcas*/
        max-width: 450px;
        
        /*Cuando hay 12 marcas
        max-width: 360px;*/
        gap: 1rem;
        justify-content: center;
    }

    .face::after {
        width: 230px;
        height: 180px;
        top: -24%;
        left: -10%;
    }
}

@media (min-width:1024px) {
    .face::after {
        width: 290px;
        height: 220px;
        top: -29%;
        left: -20%;
    }
}

@media (min-width:769px) and (max-width:960px) {

    .marks {
        /*Cuando hay 16 marcas*/
        max-width: 320px;
    
        /*Cuando hay 12 marcas
        max-width: 330px;*/
        justify-content: center;
        gap: 1rem;
    }
}

@media (min-width:1025px) {

    .marks {
        max-width: 430px;
    }
}

@media (min-width:1220px) {

    .marks {
        max-width: 520px;
    }
}
