.container {
    display: flex;
    flex-direction: row;
    width: 510px;
    height: 510px;
    border-radius: 24px;
    background-color: var(--color-white);
    background-image: url(../../assets/Luna-face.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.markL {
    width: 33%;
    height: 100%;
    position: relative;
}

.dragLeft {
    width: 70%;
    height: 30%;
    top: 34%;
    position: relative;
    left: 8%;
}

.toolKitLeft {
    position: absolute;
    top: 15%;
    
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: #fff;

    z-index: 3;
}

.markLeft {
    width: 40%;
    top: 40%;
    position: relative;
    left: 25%;
}

.markT {
    width: 34%;
    height: 100%;
    position: relative;
}
.dragTop {
    width: 70%;
    height: 19%;
    top: 22%;
    position: relative;
    left: 15%;
}

.toolKitTop {
    position: absolute;
    top: 3%;
    
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: #fff;

    z-index: 3;
}

.markTop {
    top: 23%;
    position: relative;
    left: 22%;
}

.markR {
    width: 33%;
    height: 100%;
    position: relative;
}

.dragRight {
    width: 70%;
    height: 30%;
    top: 34%;
    position: relative;
    left: 22%;
}
.toolKitRight {
    position: absolute;
    top: 25%;
    left: 20%;
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: #fff;
    
    z-index: 3;
}
.markRight {
    width: 40%;
    top: 40%;
    position: relative;
    left: 40%;
}



.rotate {
    display: flex;
    padding: 0;
    height: 44px;
    width: 44px;
    background-image: url(../../assets/rotate.svg);
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 3;
}

.rotate:hover {
    height: 44px;
    width: 44px;
    background-image: url(../../assets/rotate-hover.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.delete {
    height: 44px;
    width: 44px;
    background-image: url(../../assets/delete.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.delete:hover {
    height: 44px;
    width: 44px;
    background-image: url(../../assets/delete-hover.svg);
    background-repeat: no-repeat;
    background-size: cover;
}


@media (min-width:768px) and (max-width: 960px) {
    .container {
        width: 320px;
        height: 320px;
    }
    
}


