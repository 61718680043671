.container {
    background-color: #F3AE3D;
    color: var(--color-background);
    border-color:var(--color-yellow);
}

.container:hover {
    background-color: transparent;
    border-color: var(--color-yellow);
    color: var(--color-yellow);
}

.blueContainer {
    background-color: var(--color-background);
    color:var(--color-white);
    border-color: var(--color-background);
}

.blueContainer:hover {
    background-color: var(--color-yellow);
    border-color: var(--color-background);
    color: var(--color-background);
}

.outlineContainer {
    background-color: var(--color-white);
    color:var(--color-background);
    border-color:var(--color-background);
}

.outlineContainer:hover {
    background-color:var(--color-yellow);
    border-color: var(--color-background);
    color: var(--color-background);
}

.whiteContainer {
    background-color: var(--color-white);
    color:var(--color-background);
    border-color: var(--color-white);
}

.whiteContainer:hover {
    background-color: var(--color-yellow);
    border-color: var(--color-background);
    color: var(--color-background);
}

button {
    font-family: 'Gotha Medium';
    font-weight: bold;
    border-radius: 3rem;
    border-style: solid;

    transition: background-color 0.5s, border-color 0.5s, color 0.5s;

    font-size: 1.75rem;
    line-height: 2rem;
    padding: 1rem 3rem;
    border-width: 4px;   
}

button:hover {
    cursor: url('/src/assets/pointer.svg'), auto; 
}
button:focus {
    outline: 4px solid var(--color-yellow);
}

@media (max-width: 1024px){
    button {
        font-size:1.5rem ;
        line-height:1.75rem;
        padding: 0.75rem 2rem;
        border-width: 3px;
    }
}

@media (max-width: 768px){
    button {
        font-size:1.25rem ;
        line-height:1.5rem ;
        padding: 0.75rem 2rem;
        border-width: 3px;
    }
}