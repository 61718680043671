.container {    
    width: 100%;
    z-index: 0;
}

.logos{
    margin: 5rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logos img {
    width: 100%;
    max-width: 500px;
}

.luna {
    display: flex;
    justify-content: center;
}

.luna img {
    width: 320px;
}

.desktop {
  display: none;
}

.arrow {
    position: relative;
    width: 7vw;
    top: 0vw;
    animation: slideArrow 7s infinite alternate;
}

@keyframes slideArrow {

    0%,
    100% {
        top: 0vw;
    }

    50% {
        top: 2vw;
    }
}

@media (min-width: 1024px)  {
    .desktop {
        display: block;
    }
    .mobile { 
        display: none;
    }

    .container {
        height: 680px;
    }
    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 680px;
    }

    .luna {
        z-index: 5;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .luna img {
        width: 110%;
        padding-top: 40%;
    }

    .logos {
        padding:0 2rem 0 0;
        margin:0;
        z-index: 3;
        width: 50%;
    }
    .logos img {
        max-width: 400px;
    }

    .cloudbg {
        position: absolute;
        z-index: 0;
        right: 0px;
    }
    .cloudBackground {
        width: 70vw;
    }
    .gradient {
        background-image: url('../../assets/gradient.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 80%;
        position: absolute;
        top: 460px;
    }

}


@media (min-width: 1220px)  {
    .container, .header {
        height: 720px;
    }
    .luna img {
        padding-top: 60%;
    }
    .logos {
        width: 40%;
        padding: 0 2rem 0 0;
    }
    .logos img {
        max-width: 420px;
    }
    .gradient {
        width: 100%;
        height: 100%;
        top: 55%;
    }
}


@media (min-width: 1440px)  {
    .container, .header {
        height: 780px;
    }
    .luna img {
        width: 110%;
        padding-top: 50%;
    }

    .logos img {
        max-width: 480px;
    }

    .gradient {
        width: 100%;
        height: 140%;
    }
}

@media (min-width: 1600px)  {
    .container, .header {
        height: 840px;
    }
    .luna img {
        width: 100%;
        padding-top: 50%;
    }
    .logos {
        width: 50%;
    }
    .logos img {
        max-width: 580px;
    }

    .gradient {
        width: 100%;
        height: 140%;
    }
}