.container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2000;
    background-color: rgba(28, 72, 245, 0.6);
}

.content {
    background: var(--color-white);
    padding: 3rem;
    border-radius: 16px;
}

.title {
    font-family: 'Gotha Medium';
    font-size: 2rem;
    line-height: 2.5rem;
    font-weight: 400;
    padding: 0;
    margin: 0 0 1.5rem;
}
.title a {
    color: var(--color-background);
    text-decoration: underline;
    cursor: url('/src/assets/pointer.svg'), auto; 
}
.title a:hover {
    color: var(--color-yellow); 
}
.title a:focus {
    outline: 4px solid var(--color-yellow)
}

.buttonContainer {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}



@media (max-width: 1024px){

    .content {
        padding:2.5rem;
        width: auto;
    }

    .title {
        font-size: 1.75rem;
        line-height: 2.25rem;
    }

}


@media (max-width: 768px){

    .content {
        padding:2rem;
        width: 75%;
    }

    .title {
        font-size: 1.5rem;
        line-height: 2rem;
    }

}