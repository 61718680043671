.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
}

.h2 {
    font-family: 'Neue Plack';
    color: var(--color-white);
    font-size: 3rem;
    margin:0;
    padding: 0;
}


@media (max-width: 960px) {
    .h2 {
        font-size:2rem;
    }
}

