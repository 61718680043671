.container {
    display: flex;
    flex-direction: row;
    width: 280px;
    height: 280px;
    border-radius: 24px;
    background-color: var(--color-white);
    background-image: url(../../assets/Luna-face.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.label {
    width: 87px;
    height: 68px;
    border: 1px solid transparent;
    text-align: center;
}

.markL {
    width: 33%;
    height: 100%;
    position: relative;
}

.dragLeft {
    width: 70%;
    height: 30%;
    top: 34%;
    position: relative;
    left: 8%;
}

.toolKitLeft {
    position: absolute;
    top: 15%;
    
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: #fff;

    z-index: 3;
}

.markLeft {
    top: 40%;
    position: relative;
    left: 14%;
}

.markT {
    width: 34%;
    height: 100%;
    position: relative;
}
.dragTop {
    width: 70%;
    height: 19%;
    top: 22%;
    position: relative;
    left: 15%;
}

.toolKitTop {
    position: absolute;
    top: 3%;
    
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: #fff;

    z-index: 3;
}

.markTop {
    top: 23%;
    position: relative;
    left: 22%;
}

.markR {
    width: 33%;
    height: 100%;
    position: relative;
}

.dragRight {
    width: 70%;
    height: 30%;
    top: 34%;
    position: relative;
    left: 22%;
}
.toolKitRight {
    position: absolute;
    top: 15%;
    padding: 0.5rem;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 10px;
    background-color: #fff;
    
    z-index: 3;
}
.markRight {
    top: 40%;
    position: relative;
    left:32%;
}

@media (max-width: 1366px) and (max-height: 1024px) {

    .label {
        width: 85px;
        height: 55px;
        border: 1px solid transparent;
        text-align: center;
    }

}

@media (max-width: 600px) and (max-height: 950px) {

    .imgWithOutMark {
        width: 100px;
        height: 125px;
    }

    .imgLeft {
        width: 62px;
        height: 62px;
        transform: translateY(-30%) translateX(10%)
    }

    .imgRight {
        width: 62px;
        height: 62px;
        transform: translateY(-25%) translateX(-20%)
    }

    .imgTop {
        width: 100px;
        height: 125px;
        transform: scale(0.50)
    }

    .label {
        width: 37px;
        height: 37px;
        border: 1px solid transparent;
        text-align: center;
    }
}

.rotate {
    height: 44px;
    width: 44px;
    background-image: url(../../assets/rotate.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.rotate:hover {
    height: 44px;
    width: 44px;
    background-image: url(../../assets/rotate-hover.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.delete {
    height: 44px;
    width: 44px;
    background-image: url(../../assets/delete.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.delete:hover {
    height: 44px;
    width: 44px;
    background-image: url(../../assets/delete-hover.svg);
    background-repeat: no-repeat;
    background-size: cover;
}

.face::after {
    content: '';
    background-image: url('/src/assets/cloud-animated.svg');
    width: 360px;
    height: 280px;
    position: absolute;
    display: block;
    top: 4%;
    left: 16%;
    background-size: cover;
}

