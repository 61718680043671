.container {
    width: 100%;
    height: 100%;
    display: flex;
    background-image: url("../../assets/backgroundloading.svg");
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 2000;
    background-color: var(--color-tertiary);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.containerText{
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0 0 0 5rem;
}


.text {
    font-family: 'Neue Plack';
    color:var(--color-background);
    font-size: 3rem;
    margin:0;
    padding: 0;
}

@media (max-width: 960px) and (max-height:1024px) {
    .container {
        background-image: url("../../assets/backgroundloading-tablet.svg");
        background-size: cover;
    }
    .containerText{
        align-items: center;
        flex-direction: column-reverse;
        gap: 1rem;
        padding: 0;
    }
    
    .text{
        font-size: 2.5rem;
        width: 100%;
        text-align: center;
    }
}

@media (max-width: 420px) {
    .container {
        background-image: url("../../assets/backgroundloading-mobile.svg");
        background-size: cover;
    }
    .containerText{
        align-items: center;
        flex-direction: column-reverse;
        gap: 1rem;
        padding: 0;
    }
    
    .text{
        font-size: 2rem;
        width: 70%;
        text-align: center;
    }
}