.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    width: 100%;
}

.h2 {
    font-family: 'Neue Plack';
    text-align: center;
    color: var(--color-white);
    font-size: 2rem;
    margin: 0;
    padding: 0;
}

.tool {
    display: flex;
    flex-direction: column;
    margin-inline: auto;
    gap: 1.5rem;
    padding: 2rem 0;
}


.face {
    width: fit-content;
    height: fit-content;
    border-radius: 24px;
    padding-bottom: 0px;
    position: relative;
}

.face::after {
    content: '';
    background-image: url('/src/assets/cloud-animated.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    width: 160px;
    height: 120px;
    top: -19%;
    left: -8%;
}

.mobilemarks {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.marks {
    max-width: 600px;
    height: fit-content;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

@media (min-width: 768px) {
    .tool {
        flex-direction: row;
    }
    .mobilemarks {
        gap: 0.5rem;
    }
}

@media (max-width: 1366px) and (max-height: 1024px) {
    .marks {
        max-width: 515px;
    }

}

@media (max-width: 1180px) and (max-height: 820px) {
    .marks {
        margin-inline: 20px;
    }

}

@media (max-width: 600px) and (max-height: 950px) {
    .marks {
        display: none;
    }

    .face {
        margin: 0;
    }
}



