.container {
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 3rem 2rem;
}

.canvas {
    position: relative; 
    border-radius: 20px;
    background-color: transparent;
    border: 8px solid #fff;
    z-index: 2;
    overflow: hidden; 
    width: 100%; 
    max-width: 1000px;
    height: 0; 
}

.playButton {
    background: url('/src/assets/play.svg') no-repeat;
    background-size: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120px; 
    height: 120px; 
    transform: translate(-50%, -50%); 
    cursor: url('/src/assets/pointer.svg'), auto; 
    z-index: 3; 
    border: none; 
    padding: 0;
}
.playButton:hover, .playButton:focus {
    background: url('/src/assets/play-hover.svg') no-repeat;
    background-size: contain;
    border-radius: 50%;
}

.canvas iframe,
.canvas img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que el contenido cubra el área disponible */
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1020px;
    width: 100%;
    margin: 1rem 0;
}
.footer img { 
    max-width: 350px;
}

.conoceButton {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.conoceButton p {
    color: var(--color-white);
    text-align: center;
    font-family: 'Gotha Medium';
    margin: 0;
    padding-top: 0.5rem;
}



@media (min-width: 1400px) {
    .canvas {
        padding-top: 0;
        height: 550px;
    }
}
@media (max-width: 1400px) and (min-width: 1121px) {
    .canvas {
        border: 8px solid #fff;
        border-radius: 16px;
        padding-top: 0;
        height: 550px;
    }
}

@media (max-width: 1120px) {
    .canvas {
        border: 6px solid #fff;
        border-radius: 16px;
        padding-top: 55%;
    }
    .playButton {
        width: 80px; 
        height: 80px; 
    }
}

@media (max-width: 768px) {

    .canvas {
        border: 4px solid #fff;
        border-radius: 12px;
        padding-top: 56%;
    }
    .playButton {
        width: 60px; 
        height: 60px; 
    }
    .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }
}

@media (max-width: 1000px) and (max-height:500px){

    .canvas {
        border: 4px solid #fff;
        border-radius: 12px;
        height: 222px;
        width: 400px;
        padding-top: 0;
    }
    .playButton {
        width: 60px; 
        height: 60px; 
    }

}

@media (max-width: 1025px) and (max-height:768px){
    .container {
        height: auto;
        padding: 2rem 1.5rem 2rem;
    }
}

.volverAtras {
    max-width: 1020px;
    width: 100%;
    margin-bottom: 0.75rem;
    
}   

.volverAtras a {
    background-image: url('../../assets/arrow-back.svg');
    color: var(--color-white);
    font-family: 'Gotha Medium';
    padding-left: 1.25rem;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 1rem;
    text-decoration: none;
}
.volverAtras a:hover {
    text-decoration: underline;
}
.volverAtras a:focus {
    outline: 4px solid var(--color-yellow);
}

.pieImprenta {
    margin: 0 auto;
    padding: 1rem 0rem 3rem;
    max-width: 1440px;
}

.pieImprenta p {
    color: var(--color-white);
    font-size: 1rem;
    font-family: 'Gotha Medium';
    margin: 0;
}

.pieImprenta .marginP {
    margin:0 0 1rem;
}