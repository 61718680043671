:root {
  --color-primary: #3122B7;
  --color-secondary: #4397D0;
  --color-tertiary: #9ECEFB;
  --color-white: #FFFFFF;

  --color-background: #1C48F5;
  --color-yellow:#F3AE3D;
  --color-green:#E7FD93;
}

body {
  min-height: 100vh;
  background-color: var(--color-background);
}
body, a, button {
  cursor: url('/src/assets/pointer.svg'), auto; 
}

.App {
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  justify-content: left;
}

@font-face {
  font-family: 'Gotha Medium';
  src: url('/src/fonts/gotham_medium.woff2') format('woff2'),
       url('/src/fonts/gotham_medium.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Neue Plack';
  src: url('/src/fonts/neueplak-black.woff2') format('woff2'),
       url('/src/fonts/neueplak-black.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
