.tool{
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}

.face {
    width: fit-content;
    height: fit-content;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    position: relative;
}

.marks {
    /*Cuando hay 16 marcas
    max-width: 450px;*/

    /*Cuando hay 12 marcas
    max-width: 350px;*/
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

@media (min-width:1220px) {
    .marks {
        /*Cuando hay 16 marcas*/
        max-width: 510px;

        /*Cuando hay 12 marcas
        max-width: 490px;*/
    }
}

@media (min-width:1024px) {
    .face::after {
        content: '';
        background-image: url('/src/assets/cloud-animated.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 310px;
        height: 230px;
        top: -26%;
        left: -15%;
    }
}

@media (min-width:1366px) {
    .face::after {
        content: '';
        background-image: url('/src/assets/cloud-animated.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 390px;
        height: 290px;
        top: -33%;
        left: -24%;
    }
}

@media (min-width:769px) and (max-width:960px) {
    .face::after {
        content: '';
        background-image: url('/src/assets/cloud-animated.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 290px;
        height: 220px;
        top: -29%;
        left: -20%;
    }

   
}

@media (min-width:769px) and (max-width:960px) {
    .face::after {
        content: '';
        background-image: url('/src/assets/cloud-animated.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 200px;
        height: 160px;
        top: -28%;
        left: -24%;
    }
}

@media (min-width:961px) and (max-width: 1023px) {
    .face::after {
        content: '';
        background-image: url('/src/assets/cloud-animated.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        width: 230px;
        height: 180px;
        top: -24%;
        left: -10%;
    }
}


@media (max-width: 1366px) and (max-height: 912px) {
    .tool {
        display: flex;
        
    }   
}
