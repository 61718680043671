.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    transition: transform 1s ease;
    grid-gap: 0.5rem;
}

.pagination {
    margin-top: 1rem;
    text-align: center;
}

.pagination button {
    margin-right: 10px;
    padding: 5px 10px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
}

.pagination button:disabled {
    opacity: 0.5;
}

@keyframes slideLeft {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideRight {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

.slide-left {
    animation: slideLeft 0.5s ease forwards;
}

.slide-right {
    animation: slideRight 0.5s ease forwards;
}

.dot {
    height: 10px;
    width: 10px;
    background-color: #94A8FF;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

.active {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
    background-color: white;
}

.arrowContainer{
    margin-top: -10%;
}