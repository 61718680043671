.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem 0 5rem;

    position: relative;
    z-index: 100;
    background-color: var(--color-background);
}

@media (min-width: 1024px){
    .container {
        padding: 3rem 1.5rem 3rem 1.5rem;
    }
    
}

@media (max-width: 1220px) and (max-height: 1440px) {
    .container {
        padding: 3rem 1.5rem 3rem 1.5rem;
    }
    
}

.pieImprenta {
    margin: 0 auto;
    padding: 1rem 2rem 3rem;
    max-width: 1440px;
}

.pieImprenta p {
    color: var(--color-white);
    font-size: 1rem;
    font-family: 'Gotha Medium';
    margin: 0;
}

.pieImprenta .marginP {
    margin:0 0 1rem;
}