.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    background-image: url('../../assets/shape-bg-mobile.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 2;

    padding: 30% 0;
    margin-top: -180px;
    position: relative;
}

.textOne, .textTwo {
    font-family: 'Neue Plack';
    text-align: center;
    color: #000;
    font-size: 1.5rem;
    padding: 0;
    margin:0;
    width: 75%;
    max-width: 300px;
}

@media (min-width: 1024px)  {
  
    .container {
        height: 430px;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 5rem;

        background-image: none;

        padding: 2rem 0 0 0;
        margin-top: 0;
    }

    .bg {
        background-image: url('../../assets/info-bg-desktop.svg');

        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        width:120%;
        height: 130%;
        position: absolute;
        top: -28%;
        z-index: 2;
        left: -20%;
        z-index: 4;
    }

    .textOne, .textTwo {
        text-align: left;
        font-size: 1.5rem;
        line-height: 2rem;
        z-index: 5;
        max-width: fit-content;
    }
    .textOne {
        color: var(--color-white);
        width: 380px;
        padding-left: 12%;
        transform: translate(0, 0);
        animation: slideTextOne 7s infinite alternate;   
    }

    @keyframes slideTextOne {
        0%,
        100% {
            transform: translate(0, 0);
        }
    
        50% {
            transform: translate(0, 25%);
        }
    }
    
    .textTwo {
        color: var(--color-green);
        width: 300px;
        padding-left: 35%;
        transform: translate(0%, 0%);
        animation: slideTextTwo 7s infinite alternate;
    }

    .skin {
        border: 5px solid #E7FD93;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        background-color: transparent;
        padding:0px 10px;
    }
    
    
    @keyframes slideTextTwo {
        0%,
        100% {
            transform: translate(0, 0);
        }
    
        50% {
            transform: translate(0, 25%);
        }
    }
}

@media (min-width: 1220px)  {
    .container {
        height: 540px;
        padding-top: 5rem;
    }
    .textOne, .textTwo {
        font-size: 2rem;
        line-height: 2.25rem;
    }
    .textOne {
        width: 470px;
        padding-left: 11%;
    }
    .textTwo {
        width: 380px;
        padding-left: 31%;

    }
    .bg {
        top: -15%;
    }
}
@media (min-width: 1440px) {
    .container {
        height: 700px;
        padding-top: 2rem;
    }
    .textOne, .textTwo {
        font-size: 2.25rem;
        line-height: 2.5rem;
    }
    .textOne {
        width:560px;
        padding-left: 11%;
    }
    .textTwo {
        width: 420px;
        padding-left: 28%;

    }
    .bg {
        top: -15%;
        width: 110%;
    }
}

@media (min-width: 1650px) {
    .container {
        height: 650px;
        padding-top: 4rem;
    }
    .textOne, .textTwo {
        font-size: 2.5rem;
        line-height: 2.75rem;
    }
    .textOne {
        width:600px;
        padding-left: 11%;
    }
    .textTwo {
        width: 470px;
        padding-left: 33%;

    }
    .bg {
        top: -15%;
    }
}



@media (min-width: 767px) and (max-width: 1023px) {
    .container {
        background-image: url('../../assets/shape-bg-mobile-landscape.svg');
        padding: 20% 0;
        background-size: cover;
        margin-bottom: 10%;

    }
    .textOne, .textTwo {
        width: 80%;
        font-size: 2rem;
        max-width: inherit;
    }
}

@media (min-width: 601px) and (max-width: 768px) {
    .container {
        background-image: url('../../assets/shape-bg-mobile-large.svg');
        padding: 30% 0;
        background-size: 110%;
    }
    .textOne, .textTwo {
        max-width:500px ;
        font-size: 1.75rem;
    }
}



@media (min-width: 550px) and (max-width: 600px) {
    .container {
        background-image: url('../../assets/shape-bg-mobile-large.svg');
        padding: 25% 0;
        background-size: 110%;
    }
    .textOne, .textTwo {
        max-width:400px ;
    }
}

@media (max-width: 960px) and (max-height: 500px) {
    .container {
        background-image: url('../../assets/shape-bg-mobile-landscape.svg');
        min-height: auto;
        padding: 7rem 0;
    }
    .textOne, .textTwo {
        font-size: 1.5rem;
        width: 70%;
    }
}
