.container {
    background-color: var(--color-white);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    padding: 8px;
    cursor: url('/src/assets/pointer-drag.svg'), auto !important;
}


@media (min-width: 961px) and (max-width:1219px) {
    .container {
        width: 82px;
        height: 82px;
        padding: 6px;
    }
}

@media (min-width: 1220px) {
    .container {
        width: 100px;
        height: 100px;
        padding: 8px;
    }
}


.miniature {
    width: 100%;
}

.longPress {
    border: 2px solid rgb(0, 225, 255);
}

@keyframes borderAnimation {
    from {
        width: 100%;
        height: 100%;
    }

    to {
        width: 0;
        height: 0;
    }
}

.longPress::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid rgb(0, 225, 255);
    border-radius: 50%;
    animation: borderAnimation 0.5s linear;
}